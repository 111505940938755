import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { AngularFireAuth } from '@angular/fire/auth';
import { result } from 'lodash';
import { Router } from '@angular/router';

@Component({
  selector: 'daftar-konsumen',
  templateUrl: './daftar-konsumen.component.html',
  styleUrls: ['./daftar-konsumen.component.scss']
})
export class DaftarKonsumenComponent implements OnInit {
  displayedColumns = ['penerima', 'alamat', 'kontak', 'aksi'];
  listkonsumen: ikonsumen[] = [];
  dataSource: MatTableDataSource<ikonsumen>;

  penjualan:any = [];
  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private af: AngularFireAuth,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadkonsumen();
    // this.loadTotal();
  }

  loadkonsumen() {
    this.db.list('/debug/alamat/').snapshotChanges().subscribe(items => {
      items.forEach(item => {
        this.listkonsumen = [];
        var uid = item.key;
        this.db.list('/debug/alamat/' + uid + '/').snapshotChanges().subscribe(items2 => {
          items2.forEach(item2 => {
            // var uid2 = item2.key;
            this.listkonsumen.push(getKonsumen(uid, item2));
          });
          // console.log(this.listkonsumen);
          this.dataSource = new MatTableDataSource(this.listkonsumen);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // console.log(this.dataSource);
        })
      })
    })
  }

  async loadTotal(){
    this.db.list('/debug/Penjualan/').snapshotChanges().subscribe(items=>{
      this.penjualan = [];
      items.forEach(item=>{
        var uid = item.key;
        this.db.list('/debug/Penjualan/' + uid).snapshotChanges().subscribe(res=>{
          res.forEach(async result=>{
            // console.log(item.payload.val());
            this.penjualan.push({
              uid : item.key,
              id : result.key,
              total : result.payload.val()['totalbayar'],
            });
            // console.log(this.penjualan);

            await this.getSum(this.penjualan);
          })
        });
      })
    })
  }

  getSum(data){
    data.forEach(element => {
      console.log(element);
    });

    // const sum = data.filter(item => item.total).reduce((sum, current) => sum + current.total, 0);

  
  }

  onDetail(e) {
    const dialogRef = this.dialog.open(Dialogdetail, {
      width: '50%',
      data: e,
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  onDelete(e){
    // console.log(e);
    var r = confirm("Yakin ingin menghapus " + e.penerima + " ?");
    if (r == true) {
      this.db.database.ref('/debug/alamat/' + e.uid + '/' + e.uid2).remove();
    }
  }
}

@Component({
  selector: 'Dialogdetail',
  templateUrl: 'Dialogdetail/detail-konsumen.component.html',
  styleUrls: ['Dialogdetail/detail-konsumen.component.scss']
})
export class Dialogdetail {
  modelKonsumen:any = [];
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
    this.modelKonsumen = this.data;
  }

}

function getKonsumen(uid: string, data: any): ikonsumen {
  return {
    uid: uid,
    uid2: data.key,
    alamat: data.payload.val()['alamat'],
    kecamatan: data.payload.val()['kecamatan'],
    kelurahan: data.payload.val()['kelurahan'],
    kodePos: data.payload.val()['kodePos'],
    kontak: data.payload.val()['kontak'],
    kota: data.payload.val()['kota'],
    lat: data.payload.val()['lat'],
    lng: data.payload.val()['lng'],
    namaAlamat: data.payload.val()['namaAlamat'],
    penerima: data.payload.val()['penerima'],
  }
}
export interface ikonsumen {
  uid: string;
  uid2: string;
  alamat: string;
  kecamatan: string;
  kelurahan: string;
  kodePos: string;
  kontak: string;
  kota: string;
  lat: string;
  lng: string;
  namaAlamat: string;
  penerima: string;
}
export const COMPONENT_LIST = [
  DaftarKonsumenComponent, Dialogdetail,
]