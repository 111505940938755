import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase } from '@angular/fire/database';
import { Howl, Howler } from 'howler';
import { ToastrService } from 'ngx-toastr';
@Injectable()
export class MessagingService {
    // Retrieve Firebase Messaging object.

    currentMessage = new BehaviorSubject(null);
    constructor(
        private db: AngularFireDatabase,
        private toastr: ToastrService,
        private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messaging.subscribe(
            (_messaging) => {
                _messaging.onMessage = _messaging.onMessage.bind(_messaging);
                _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
            }
        )
    }
    requestPermission() {
        let fcm = [];
        item: Boolean;
        this.angularFireMessaging.requestToken.subscribe(
            (res) => {
                localStorage.setItem("FCM", res);

            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload) => {
                var sound = new Howl({
                    src: ['assets/music/eventually.mp3']
                });
                sound.play();
                this.toastr.info(payload['notification']['body'], payload['notification']['title'], {
                    timeOut: 3000,
                    positionClass: 'toast-bottom-right'
                });
                this.currentMessage.next(payload);
            })
    }
}