// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  BaseUrl: 'https://fcm.googleapis.com/fcm/send',
  dbg: 'debug/',
  dbg2: 'debug2/',
  produksi : 'production/',
  serverKey: 'key=AAAA_msl4ps:APA91bHle8RwzT60ggwekr72s3kbZW5HyaY4VnTtRcnKSxn4toyalgUITlUA6zUxlQb7VHaJQ0M1Dnm81zF4xwajKkNwgVQGQIKPPKb80j0yXf5qbAhM66MhQCNOCPXUt8uqkJE5Awot',
  firebaseConfig: {
    apiKey: "AIzaSyB0_6xMVc4dErtaf87PybVE6ZTX3gLOsv0",
    authDomain: "pasarpagisegar.firebaseapp.com",
    databaseURL: "https://pasarpagisegar.firebaseio.com",
    projectId: "pasarpagisegar",
    storageBucket: "pasarpagisegar.appspot.com",
    messagingSenderId: "1092719338139",
    appId: "1:1092719338139:web:4c8f6bddea5755c4295c3e"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
