import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
declare var jQuery: any;
import { saveAs } from 'file-saver';

@Component({
  selector: 'pengaturan-utama',
  templateUrl: './pengaturan.component.html',
  styleUrls: ['./pengaturan.component.scss']
})
export class PengaturanUtama implements OnInit {

  listPengaturan: PengaturanData[] = [];
  listBank: BankAkun[] = [];
  listCare: CareData[] = [];
  listPromo: PromoData[] = [];
  listNotif: NotifData[] = [];
  listDeskripsi: DeskData[] = [];
  listOngkir: OngkirData[] = [];
  dialogRef: any = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  dataSource: MatTableDataSource<PengaturanData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    public dialogBank: MatDialog,
    private _fuseSplashScreenService: FuseSplashScreenService,
    public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadNotif();
  }

  loadNotif() {
    this.db.list('debug/settings/').snapshotChanges()
      .subscribe(pembayaran => {
        this.listPengaturan = [];
        this.listBank = [];
        this.listPromo = [];
        this.listCare = [];
        this.listNotif = [];
        this.listDeskripsi = [];
        this.listOngkir = [];
        pembayaran.forEach(items => {
          if (items.key === 'bank') {
            this.db.list('debug/settings/bank/').snapshotChanges().subscribe(banks => {
              this.listBank = [];
              banks.forEach(element => {
                this.listBank.push(getBank(element));
              })
            })
            // console.log(items.key);
            // let bank: any = items.payload.val();
            // bank.forEach((element) => {
            //   this.listBank.push(getBank(element));
            // });
          }

          if (items.key === 'notifikasi') {
            let notif: any = items.payload.val();
            notif.forEach((element) => {
              this.listNotif.push(getNotif(element));
            });
          }

          if (items.key === 'care') {
            let care: any = items.payload.val();
            care.forEach((element) => {
              this.listCare.push(getCare(element));
            });
          }

          if (items.key === 'promo') {
            let promo: any = items.payload.val();
            promo.forEach((element) => {
              this.listPromo.push(getPromo(element));
            });
          }

          if (items.key === 'deskripsi') {
            let deskripsi: any = items.payload.val();
            deskripsi.forEach((element) => {
              this.listDeskripsi.push(getDeskripsi(element));
            });
          }

          if (items.key === 'ongkir') {
            let ongkir: any = items.payload.val();
            ongkir.forEach((element) => {
              this.listOngkir.push(getOngkir(element));
            });
          }

          this.listPengaturan.push(getlistNotif(items.key));

        });

        this.dataSource = new MatTableDataSource(this.listPengaturan);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  getRecord(item) {
    // console.log(item);
    if (item.title === 'bank') {
      const dialogref = this.dialogBank.open(editBank, {
        panelClass: 'dialog',
        hasBackdrop: true,
        width: '80%',
        height: '70%',
        data: this.listBank,
      });
      dialogref.afterClosed().subscribe(result => {
        console.log(result);
        // if (result !== 'close') {
        //   this.loadSpinner();
        //   this.db.database.ref('debug/settings/').set({
        //     'bank': result,
        //     'notifikasi': this.listNotif,
        //     'promo': this.listPromo,
        //     'care': this.listCare,
        //     'deskripsi': this.listDeskripsi,
        //     'ongkir': this.listOngkir,
        //   }).then(() => {
        //     this.closeSpinner();
        //   });
        // }
      });
    }

    if (item.title === 'notifikasi') {
      const dialogref = this.dialogBank.open(editNotif, {
        panelClass: 'dialog',
        hasBackdrop: true,
        width: '80%',
        height: '70%',
        data: this.listNotif,
      });
      dialogref.afterClosed().subscribe(result => {
        if (result !== 'close') {
          this.loadSpinner();
          this.db.database.ref('debug/settings/notifikasi/').set(
            result
            // 'bank': this.listBank,
            // 'notifikasi': result,
            // 'promo': this.listPromo,
            // 'care': this.listCare,
            // 'deskripsi': this.listDeskripsi,
            // 'ongkir': this.listOngkir,
          ).then(() => {
            this.closeSpinner();
          });
        }
      });
    }

    if (item.title === 'promo') {
      const dialogref = this.dialogBank.open(editPromo, {
        panelClass: 'dialog',
        hasBackdrop: true,
        width: '40%',
        height: '50%',
        data: this.listPromo,
      });
      dialogref.afterClosed().subscribe(result => {
        if (result !== 'close') {
          this.loadSpinner();
          this.db.database.ref('debug/settings/promo/').set(
            result,
            // 'bank': this.listBank,
            // 'notifikasi': this.listNotif,
            // 'promo': result,
            // 'care': this.listCare,
            // 'deskripsi': this.listDeskripsi,
            // 'ongkir': this.listOngkir,
          ).then(() => {
            this.closeSpinner();
          });
        }

      });
    }

    if (item.title === 'care') {
      const dialogref = this.dialogBank.open(editCare, {
        panelClass: 'dialog',
        hasBackdrop: true,
        width: '30%',
        height: '40%',
        data: this.listCare,
      });
      dialogref.afterClosed().subscribe(result => {
        if (result !== 'close') {
          this.loadSpinner();
          this.db.database.ref('debug/settings/care/').set(
            result,
            // 'bank': this.listBank,
            // 'notifikasi': this.listNotif,
            // 'promo': this.listPromo,
            // 'care': result,
            // 'deskripsi': this.listDeskripsi,
            // 'ongkir': this.listOngkir,
          ).then(() => {
            this.closeSpinner();
          });
        }

      });
    }

    if (item.title === 'deskripsi') {
      const dialogref = this.dialogBank.open(editDeskripsi, {
        panelClass: 'dialog',
        hasBackdrop: true,
        width: '80%',
        height: '70%',
        data: this.listDeskripsi,
      });
      dialogref.afterClosed().subscribe(result => {
        if (result !== 'close') {
          this.loadSpinner();
          this.db.database.ref('debug/settings/deskripsi/').set(
            result
            // 'bank': this.listBank,
            // 'notifikasi': this.listNotif,
            // 'promo': this.listPromo,
            // 'care': this.listCare,
            // 'deskripsi': result,
            // 'ongkir': this.listOngkir,
          ).then(() => {
            this.closeSpinner();
          });
        }

      });
    }

    if (item.title === 'ongkir') {
      const dialogref = this.dialogBank.open(editOngkir, {
        panelClass: 'dialog',
        hasBackdrop: true,
        width: '60%',
        data: this.listOngkir,
      });
      dialogref.afterClosed().subscribe(result => {
        if (result !== 'close') {
          this.loadSpinner();
          this.db.database.ref('debug/settings/ongkir/').set(
            result,
            // 'bank': this.listBank,
            // 'notifikasi': this.listNotif,
            // 'promo': this.listPromo,
            // 'care': this.listCare,
            // 'deskripsi': this.listDeskripsi,
            // 'ongkir': result,
          ).then(() => {
            this.closeSpinner();
          });
        }
      });
    }

  }
}

@Component({
  selector: 'editnotif-dialog',
  templateUrl: 'notifikasi/editnotif-dialog.html',
  styleUrls: ['notifikasi/editnotif-dialog.scss']
})
export class editNotif {
  Modelnotif: any = [];
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<editNotif>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.Modelnotif = this.data;
  }

  onSave() {
    this.dialogRef.close(this.Modelnotif);
  }
}


@Component({
  selector: 'editbank-dialog',
  templateUrl: 'bank/bank-dialog.html',
  styleUrls: ['bank/bank-dialog.scss']
})
export class editBank {
  Modelbank: any = [];
  listBank: BankAkun[] = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<editBank>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    // console.log(this.data);
    // this.Modelbank = this.data;
    this.loadbank();
  }

  loadbank() {
    this.db.list('debug/settings/bank/').snapshotChanges().subscribe(banks => {
      this.listBank = [];
      banks.forEach(element => {
        this.listBank.push(getBank(element));
      });
      this.Modelbank = this.listBank;
    });
  }

  tambah() {
    const dialogref = this.dialog.open(DialogTambahBank, {
      panelClass: 'dialog',
      hasBackdrop: true,
      width: '40%',
      // height: '70%',
    });

    dialogref.afterClosed().subscribe(result => {
      // if (result !== '') {
      //   console.log(result);
      //   this.Modelbank.push({ Nama: result.Nama, atasNama: result.atasNama, image: result.image, rekening: result.rekening });
      // }
    });
  }

  onRemove(e){
    // console.log(e);
    // this.Modelbank.splice(i, 1);
    var r = confirm("Yakin ingin menghapus " + e.Nama + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref('debug/settings/bank/' + e.id + '/').remove();
      this.storage.ref(e.filename).delete().subscribe(() => {
        this.closeSpinner();
      })
    }
  }

  onSave() {
    // console.log(this.Modelbank);
    // this.dialogRef.close(this.Modelbank);
    this.loadSpinner();
    this.Modelbank.forEach(e => {
      this.db.database.ref('debug/settings/bank/' + e.id + '/').set({
        'Nama': e.Nama,
        'atasNama': e.atasNama,
        'image': e.image,
        'rekening': e.rekening,
        'filename': e.filename,
      }).then(()=>{
        this.closeSpinner();
        this.dialogRef.close();
      });
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }
  
}


@Component({
  selector: 'editbank-dialog',
  templateUrl: 'bank/tambahbank.html',
  styleUrls: ['bank/bank-dialog.scss']
})
export class DialogTambahBank {
  Modelbank: any = [];
  imageChangedEvent: any = '';
  gambar: any;
  file: any;

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogTambahBank>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {

  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }
  getFileAttachment(event: any) {
    this.file = '';
    // this.imageChangedEvent = event;
    // console.log(event);
    if (event.target.files && event.target.files[0]) {
      this.file = event.target.files[0];
      console.log(this.file);
      const reader = new FileReader();
      reader.onload = e => this.gambar = reader.result;
      // console.log(reader.readAsDataURL(file));

      reader.readAsDataURL(this.file);
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  onSave() {
    if (this.file === '') {
      alert('Gambar tidak boleh kosong !');
      return;
    }
    this.loadSpinner();
    var currentUnixTime = Date.now();
    const filePath = this.Modelbank.Nama + '.webp';
    const fileRefr = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, this.file);
    task.snapshotChanges().pipe(
      finalize(() => {
        var downloadURL = fileRefr.getDownloadURL()
        downloadURL.subscribe(url => {
          if (url) {
            this.db.database.ref('/debug/settings/bank/' + currentUnixTime + '/').set(
              {
                'Nama': this.Modelbank.Nama,
                'atasNama': this.Modelbank.atasNama,
                'image': url,
                'rekening': this.Modelbank.rekening,
                'filename': filePath,
              }
            ).then(() => {
              // this.storage.ref(this.data.image).delete();
              this.closeSpinner();
              this.dialogRef.close({
                Nama: this.Modelbank.Nama,
                atasNama: this.Modelbank.atasNama,
                image: this.file,
                rekening: this.Modelbank.rekening,
              });
            })
          }
        })
      })
    ).subscribe();
  }
}

@Component({
  selector: 'editpromo-dialog',
  templateUrl: 'promo/promo-dialog.html',
  styleUrls: ['promo/promo-dialog.scss']
})
export class editPromo {
  Modelpromo: any = [];
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialogRef<editPromo>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.Modelpromo = this.data;
  }

  onSave() {
    this.dialog.close(this.Modelpromo);
  }
}


@Component({
  selector: 'editcare-dialog',
  templateUrl: 'care/care-dialog.html',
  styleUrls: ['care/care-dialog.scss']
})
export class editCare {
  Modelcare: any = [];
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialogRef<editCare>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.Modelcare = this.data;
  }

  onSave() {
    this.dialog.close(this.Modelcare);
  }
}

@Component({
  selector: 'editdeskripsi-dialog',
  templateUrl: 'deskripsi/dialogdeskripsi.html',
  styleUrls: ['care/care-dialog.scss']
})
export class editDeskripsi {
  Modeldeskripsi: any = [];
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<editDeskripsi>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    this.Modeldeskripsi = this.data[0];
  }

  onSave() {
    this.dialogRef.close(this.Modeldeskripsi);
  }
}

@Component({
  selector: 'editongkir-dialog',
  templateUrl: 'ongkir/ongkir.html',
  styleUrls: ['care/care-dialog.scss']
})
export class editOngkir {
  ModelOngkir: any = [];
  title: string;
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<editOngkir>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    this.ModelOngkir = this.data;
    if (this.ModelOngkir[0].status === true) {
      this.title = 'Aktif';
    } else {
      this.title = 'Tidak Aktif';
    }
  }

  setValue(i, e) {
    if (e.checked === true) {
      this.ModelOngkir[i].status = e.checked;
      this.title = 'Aktif';
    } else {
      this.ModelOngkir[i].status = e.checked;
      this.title = 'Tidak Aktif';
    }
  }

  onSave() {
    // console.log(this.ModelOngkir);
    this.dialogRef.close(this.ModelOngkir);
  }
}

export interface PengaturanData {
  title: string;
}

function getlistNotif(key: string): PengaturanData {
  return {
    title: key,
  };
}

export interface BankAkun {
  id: string;
  Nama: string;
  atasNama: string;
  image: string;
  rekening: string;
  filename: string;
}

function getBank(data: any): BankAkun {
  return {
    id: data.key,
    Nama: data.payload.val()['Nama'],
    image: data.payload.val()['image'],
    atasNama: data.payload.val()['atasNama'],
    rekening: data.payload.val()['rekening'],
    filename: data.payload.val()['filename'],
  };
}
export interface CareData {
  nomor: string;
}

function getCare(data: any): CareData {
  return {
    nomor: data['nomor'],
  };
}

export interface NotifData {
  isi: string;
  judul: string;
}

function getNotif(data: any): NotifData {
  return {
    isi: data['isi'],
    judul: data['judul'],
  };
}

export interface PromoData {
  isi: string;
}

function getPromo(data: any): PromoData {
  return {
    isi: data['isi'],
  };
}

export interface DeskData {
  deskripsi: string;
  image: string;
  title: string;
}

function getDeskripsi(data: any): DeskData {
  return {
    deskripsi: data['deskripsi'],
    image: data['image'],
    title: data['title'],
  };
}

function getOngkir(data: any): OngkirData {
  return {
    status: data['status'],
    lat: data['lat'],
    long: data['long'],
    ongkirperkm: data['ongkirperkm'],
    transaksiMin: data['transaksiMin'],
    jarakOngkir: data['jarakOngkir'],
  };
}

export interface OngkirData {
  status: string;
  lat: string;
  long: string;
  ongkirperkm: string;
  transaksiMin: string;
  jarakOngkir: string;
}

export const COMPONENT_LIST = [
  PengaturanUtama,
  editNotif,
  editBank,
  editCare,
  editPromo,
  editDeskripsi,
  editOngkir,
  DialogTambahBank,
];

