import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { FormControl, Validators, Form, NgForm } from '@angular/forms';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiService } from 'app/services/api.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'laporan',
  templateUrl: './laporan.component.html',
  styleUrls: ['./laporan.component.scss']
})
export class LaporanComponent implements OnInit {
  displayedColumns = ['img', 'nama', 'tgl', 'jlh'];
  listSale: MenuData[] = [];
  finalArr: any = [];
  finalArr2: any = [];
  dataSource: MatTableDataSource<any>;

  listProduk: any = [];
  listReport: ireport[] = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  listMonth: any = [];
  bulan: any;
  date: any = [];

  ExcelData: any = [];
  finalExcel: any[] = [];

  temp_arr: any = [];
  temp_import: any = [];

  excelHeaders: any = ["ID KATEGORI", "ID PRODUK", "NAMA PRODUK", "HARGA"];
  excelIsi: any = ["123", "456", "KECAP", "1200"];
  templateToExcel: any = [];

  monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  total: number = 0;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private af: AngularFireAuth,
    private toastr: ToastrService,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  async ngOnInit() {
    // this.LoadData();
    this.loadFinal();
    this.date = moment().months();
    this.bulan = this.monthNames[this.date];
    this.listMonth = moment.months();
    this.export();
  }

  loadFinal() {
    this.db.list('/debug/sale/').snapshotChanges().subscribe(res => {
      this.listProduk = [];
      res.forEach(sale => {
        let saleId = sale.key;
        this.db.list('/debug/sale/' + saleId + '/').snapshotChanges().subscribe(res2 => {
          res2.forEach(sale2 => {
            // console.log(sale2.payload.val());
            this.db.list('/debug/kategori/').snapshotChanges().subscribe(items1 => {
              items1.forEach(item1 => {
                let idKategori = item1.key;
                this.db.list('/debug/kategori/' + idKategori + '/produk/').snapshotChanges().subscribe(items2 => {
                  items2.forEach(item2 => {
                    if (item2.key === sale.key) {
                      this.listProduk.push(getListProduk(
                        idKategori,
                        item2.key,
                        item2.payload.val()['nama'],
                        item2.payload.val()['sub'],
                        item2.payload.val()['image'],
                        moment(new Date(Number(sale2.key.split('-')[0]))).format('DD/MM/YYYY'),
                        sale2.payload.val()['jlh'],
                        sale2.payload.val()['satuan'],
                        sale2.payload.val()['jlhgram'],
                        sale2.payload.val()['satuangram']));
                      this.dataSource = new MatTableDataSource(this.listProduk);
                      this.dataSource.paginator = this.paginator;
                      this.dataSource.sort = this.sort;
                    }
                  });
                  // console.log(this.listProduk.length);
                });
              });
            });
          });
        })
      });
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  onChange(e) {
    let temp = [];
    setTimeout(() => {
      temp = this.listProduk.filter(item => moment(item.penjualan).format("MMMM") === e);
      this.dataSource = new MatTableDataSource(temp);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 500);
    // console.log(temp);
  }


  cetak(): void {
    const dialogRef = this.dialog.open(printDialog, {
      width: '95%',
      height: '90%',
      data: {
        penjualan: this.listProduk,
      }
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  cetakOrder(): void {
    const dialogRef = this.dialog.open(printbyOrderDialog, {
      width: '95%',
      height: '90%',
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  export() {
    this.db.list('/debug/kategori')
      .snapshotChanges()
      .subscribe(items => {
        this.listReport = [];
        items.forEach(item => {
          let idkategori = item.key;
          this.db.list('/debug/kategori/' + idkategori + '/produk/').snapshotChanges().subscribe(items2 => {
            items2.forEach(item2 => {
              this.db.list('/debug/kategori/' + idkategori + '/produk/' + item2.key + '/harga/').snapshotChanges().subscribe(result => {
                result.forEach(row => {
                  this.listReport.push(
                    getReport(
                      idkategori,
                      item2.key,
                      item2.payload.val()['nama'],
                      row.payload.val()['ukuran'],
                      row.payload.val()['ukuranSampai'],
                      row.payload.val()['satuan'],
                      row.payload.val()['nominal'],
                      item2.payload.val()['disc'],
                      item2.payload.val()['kandungan'],
                      item2.payload.val()['sub'],
                      item2.payload.val()['type'],
                    ));
                });
                // console.log(this.listReport);
              })
            });
          });
        });
      });

  }

  toExcel() {
    // const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.listReport);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, "List Produk" + ".xlsx");
  }

  openfile() {
    $('#fileSrc').trigger('click');
  }

  excelFile(args: any) {
    var files = args.target.files; // FileList object
    this.parseExcel(files[0]);

    // console.log(files);
  }

  parseExcel(file) {
    // console.log(file);
    // this.loadSpinner();

    var reader = new FileReader();
    // var i:number = 0;
    reader.onload = (e) => {
      var data = (<any>e.target).result;
      var workbook = XLSX.read(data, {
        type: 'binary'
      });
      workbook.SheetNames.forEach((async function (sheetName) {
        // Here is your object
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        var json_object = JSON.stringify(XL_row_object);
        // console.log(XL_row_object);
        this.ExcelData.push(XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]));
        // this.StoreDB(await this.ExcelData);
        this.StoreDB(await this.ExcelData);
        //this.gridObj.dataSource = JSON.parse(json_object);
      }).bind(this), this);
    };

    reader.onerror = function (ex) {
      // console.log(ex);
    };
    reader.readAsBinaryString(file);
  };

  gabung(hasil) {
    var harga = [];
    hasil.forEach(b => {
      harga.push({
        'ukuran': b.ukuran === undefined ? '0' : b.ukuran.toString(),
        'ukuranSampai': b.ukuranSampai === undefined ? '0' : b.ukuranSampai.toString(),
        'satuan': b.satuan === undefined ? '' : b.satuan.toString(),
        'nominal': b.nominal === undefined ? '0' : b.nominal.toString(),
      });
      return harga;
    });
    return harga;
  }

  async StoreDB(data) {
    var total = data[0].length;
    this.temp_import = [];
    this.loadSpinner();
    var i = 1;
    data[0].forEach(async res => {
      var hasil = data[0].filter(e => e.idproduk === res.idproduk);
      var harga = await this.gabung(hasil);

      await this.db.database.ref('/debug/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/disc/').set(
        res.disc === undefined ? 0 : res.disc,
      );

      await this.db.database.ref('/debug/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/kandungan/').set(
        res.kandungan === undefined ? '' : res.kandungan,
      );

      await this.db.database.ref('/debug/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/nama/').set(
        res.nama === undefined ? '' : res.nama,
      );

      await this.db.database.ref('/debug/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/sub/').set(
        res.sub === undefined ? '' : res.sub,
      );

      await this.db.database.ref('/debug/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/type/').set(
        res.type === undefined ? '' : res.type
      );

      await this.db.database.ref('/debug/kategori/' + res.idkategori + '/produk/' + res.idproduk + '/harga/').set(
        harga,
      );
      i++;
      if (i === total) {
        this.closeSpinner();
        this.toastr.success('Import Selesai', 'Informasi');
      }
    });
    // return true;

  }


}


@Component({
  selector: 'print-dialog',
  templateUrl: './printdialog.html',
  styleUrls: ['./laporan.component.scss']
})
export class printDialog {
  mergeProduct: any = [];
  constructor(
    public dialogRef: MatDialogRef<printDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,) {
  }

  async ngOnInit() {
    // for (const dt of this.data.penjualan) {
    //   let tmp = this.data.penjualan.filter(e => e.penjualan === dt.penjualan && e.idProduk === dt.idProduk)
    //   let hasil = await this.mergeArray(tmp)
    //   // console.log(hasil)
    // }
    // this.getTotal();
  }

  getTotal2() {
    // first, convert data into a Map with reduce
    let counts = this.data.penjualan.reduce((prev, curr) => {
      let count = prev.get(curr.idProduk) || 0;
      prev.set(curr.idProduk, curr.jlh + count);
      return prev;
    }, new Map());

    // then, map your counts object back to an array
    let reducedObjArr = [...counts].map(([key, value]) => {
      return { key, value }
    })

    console.log(reducedObjArr);
  }

  getTotal() {
    let temp = {};
    let obj = null;

    for (let i = 0; i < this.data.penjualan.length; i++) {
      obj = this.data.penjualan[i];

      if (!temp[obj.penjualan] && !temp[obj.idProduk]) {
        temp[obj.idProduk] = obj
      } else {
        temp[obj.idProduk].jlh += obj.jlh;
      }

      var result = [];
      for (var prop in temp) {
        result.push(temp[prop]);
      }
      console.log(result)
    }
  }

  async mergeArray(tmp) {
    // this.mergeProduct = [];
    var gabung = [];
    let total = 0
    tmp.map(num => {
      total += num.jlh;
    });
    // console.log(total)

    tmp.forEach(e => {
      // total += e.jlh
      gabung.push({
        idKategori: e.idKategori,
        idProduk: e.idKategori,
        image: e.idKategori,
        jlh: total,
        jlhgram: e.jlhgram,
        nama: e.nama,
        penjualan: e.penjualan,
        satuan: e.satuan,
        satuangram: e.satuangram,
        sub: e.sub,
      })
      // return gabung
    });
    console.log(gabung)
    // return gabung
  }
}


@Component({
  selector: 'print-dialog',
  templateUrl: './printbyorderdialog.html',
  styleUrls: ['./laporan.component.scss']
})
export class printbyOrderDialog {
  listmenu: MenuOrder[] = [];
  constructor(
    public dialogRef: MatDialogRef<printDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,) { }

  ngOnInit(): void {
    this.loadpenjualan();
  }

  loadpenjualan() {
    this.db.list('/debug/Penjualan/').snapshotChanges().subscribe(items => {
      items.forEach(item => {
        var idInduk = item.key;
        this.listmenu = [];
        this.db.list('/debug/Penjualan/' + idInduk).snapshotChanges().subscribe(item2 => {
          item2.forEach(order => {
            this.listmenu.push(GetListOrder(idInduk, order.key, order.payload.val()['carabayar'], order.payload.val()['kurir'], order.payload.val()['nama'],
              order.payload.val()['pengiriman'], order.payload.val()['tgl'], order.payload.val()['status'], order.payload.val()['tokenFcm'],
              order.payload.val()['totalbayar'], order.payload.val()['chart']));
          });
          // this.onNew();
          // this.dataSource = new MatTableDataSource(this.listmenu);
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
        });
      });
    });
  }
}

function getListSale(idProduk: string, idList: string, jlh: string): MenuData {
  return {
    idProduk: idProduk,
    idList: idList,
    jlh: jlh,
  }
}

function getListProduk(idKategori: string, idProduk: string, nama: string,
  sub: string, image: any[], penjualan: any, jlh: string, satuan: string, jlhgram: string, satuangram: string): MenuProduk {
  return {
    idKategori: idKategori,
    idProduk: idProduk,
    nama: nama,
    sub: sub,
    image: image,
    penjualan: penjualan,
    jlh: jlh,
    satuan: satuan,
    jlhgram: jlhgram,
    satuangram: satuangram,
  }
}

function GetListOrder(idInduk: string, id: string, carabayar: string, kurir: any, nama: string, pengiriman: any, tgl: any,
  status: string, tokenFcm: any, totalbayar: number, chart: chart[],): MenuOrder {

  return {
    idInduk: idInduk.toString(),
    id: id.toString(),
    carabayar: carabayar,
    kurir: kurir,
    nama: nama,
    pengiriman: pengiriman,
    tgl: tgl,
    status: status,
    tokenFcm: tokenFcm,
    totalbayar: totalbayar,
    chart: chart,
  };
}

export interface MenuData {
  idProduk: string;
  idList: string;
  jlh: string;
}

export interface MenuProduk {
  idKategori: string;
  idProduk: string;
  nama: string,
  sub: string,
  image: any[];
  penjualan: any,
  jlh: string,
  satuan: string,
  jlhgram: string,
  satuangram: string,
}

export interface MenuOrder {
  idInduk: string;
  id: string;
  carabayar: string;
  kurir: any[];
  nama: string;
  pengiriman: any;
  status: string;
  tgl: any;
  tokenFcm: any;
  totalbayar: number;
  chart: chart[];
}

export interface chart {
  idProduk: string;
  harga: number;
  jumlah: number;
  namaproduk: string;
  url: any;

}

function getReport(idkategori: string, idproduk: string,
  nama: string, ukuran: any, ukuranSampai: any,
  satuan: any, nominal: any,
  disc: any, kandungan: any,
  sub: any, type: any): ireport {
  return {
    idkategori: idkategori,
    idproduk: idproduk,
    nama: nama,
    ukuran: ukuran,
    ukuranSampai: ukuranSampai,
    satuan: satuan,
    nominal: nominal,
    disc: disc,
    kandungan: kandungan,
    sub: sub,
    type: type
  }
}

export interface ireport {
  idkategori: string;
  idproduk: string;
  nama: string;
  ukuran: any;
  ukuranSampai: any;
  satuan: any;
  nominal: any;
  disc: any;
  kandungan: any;
  sub: any;
  type: any;
}

export interface iDataReport {
  idKategori: string;
  idProduk: string;
  image: any;
  jlh: Number;
  jlhgram: Number;
  nama: string;
  penjualan: any;
  satuan: string;
  satuangram: string;
  sub: string;
}
export const COMPONENT_LIST = [
  LaporanComponent, printDialog, printbyOrderDialog,
]