import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn, startWith, tap, windowWhen } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { FormControl, Validators, Form, NgForm, FormGroup } from '@angular/forms';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError, MatAutocompleteSelectedEvent } from '@angular/material';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ApiService } from 'app/services/api.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'Order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class Order implements OnInit {

  displayedColumns = ['tgl', 'nama', 'status', 'pembayaran'];
  listmenu: MenuData[] = [];
  listnotifpembayaran: notifPembayaran[] = [];
  dialogRef: any = [];
  dataSource: MatTableDataSource<MenuData>;

  tmpWait: MenuData[] = [];
  tmpConfirm: MenuData[] = [];
  tmpPaid: MenuData[] = [];
  tmpNew: MenuData[] = [];
  tmpDone: MenuData[] = [];
  tmpDelivered: MenuData[] = [];

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private af: AngularFireAuth,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }


  async ngOnInit() {
    let auth = await localStorage.getItem("AUTH");
    if (auth === null) {
      this.router.navigate(['/login']);
    }
    this.loadpenjualan();
    this.loadnotif();
  }


  loadpenjualan() {
    this.db.list('/debug/Penjualan/').snapshotChanges().subscribe(items => {
      items.forEach(item => {
        var idInduk = item.key;
        this.listmenu = [];
        this.tmpNew = [];
        this.tmpWait = [];
        this.tmpDone = [];
        this.tmpDelivered = [];
        this.tmpDelivered = [];
        this.tmpPaid = [];
        this.tmpConfirm = [];
        this.db.list('/debug/Penjualan/' + idInduk).snapshotChanges().subscribe(item2 => {
          // this.listmenu = [];
          item2.forEach(order => {
            this.listmenu.push(GetListOrder(idInduk, order.key, order.payload.val()['carabayar'], order.payload.val()['kurir'], order.payload.val()['nama'],
              order.payload.val()['pengiriman'], order.payload.val()['tgl'], order.payload.val()['status'], order.payload.val()['tokenFcm'],
              order.payload.val()['totalbayar'], order.payload.val()['chart'], order.payload.val()['statusbayar']));
          });
          // console.log(this.listmenu);
          this.onNew();
          this.dataSource = new MatTableDataSource(this.listmenu);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          console.log(this.dataSource);
        });
      });
    });
  }

  loadnotif() {
    this.listnotifpembayaran = [];
    this.db.list('/debug/settings/notifikasi/')
      .snapshotChanges()
      .subscribe(pembayaran => {
        pembayaran.forEach(items => {
          this.listnotifpembayaran.push(getListNotifPembayaran(items.payload.val()['judul'], items.payload.val()['isi']));
        });
      });
  }

  onNew() {
    this.tmpNew = [];
    this.listmenu.forEach(data => {
      if (data.status === 'new') {
        this.tmpNew.push(data);
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.tmpNew);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);
      }
    });
  }
  onWaiting() {
    this.tmpWait = [];
    this.listmenu.forEach(data => {
      if (data.status === 'waiting') {
        this.tmpWait.push(data);
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.tmpWait);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);
      }
    });
  }

  onDone() {
    this.tmpDone = [];
    this.listmenu.forEach(data => {
      if (data.status === 'done') {
        this.tmpDone.push(data);
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.tmpDone);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);
      }
    });
  }

  onDelivered() {
    this.tmpDelivered = [];
    this.listmenu.forEach(data => {
      if (data.status === 'delivered') {
        this.tmpDelivered.push(data);
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.tmpDelivered);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);
      }
    });
  }

  onFinish() {
    this.tmpDelivered = [];
    this.listmenu.forEach(data => {
      if (data.status === 'finish') {
        this.tmpDelivered.push(data);
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.tmpDelivered);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);
      }
    });
  }

  onPaid() {
    this.tmpPaid = [];
    this.listmenu.forEach(data => {
      if (data.status === 'paid') {
        this.tmpPaid.push(data);
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.tmpPaid);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);
      }
    });
  }

  onConfirm() {
    this.tmpConfirm = [];
    this.listmenu.forEach(data => {
      if (data.status === 'confirm') {
        this.tmpConfirm.push(data);
        setTimeout(() => {
          this.dataSource = new MatTableDataSource(this.tmpConfirm);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }, 500);
      }
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }


  confirmDialog(ex): void {
    this.dialogRef = this.dialog.open(dialogConfirm, {
      width: '50%',
      height: '80%',
      data: { penjualan: ex, notif: this.listnotifpembayaran },
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }

  cetak(): void {
    this.dialogRef = this.dialog.open(printDialog, {
      width: '95%',
      height: '90%',
      data: {
        penjualan: this.listmenu,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }
}

@Component({
  selector: 'print-dialog',
  templateUrl: './printdialog.html',
  styleUrls: ['./order.component.scss']
})
export class printDialog {

  constructor(
    public dialogRef: MatDialogRef<printDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,) { }

  ngOnInit(): void {
    console.log(this.data);
  }
}

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirmdialog/confirm-dialog.html',
  styleUrls: ['confirmdialog/confirm.component.scss']
})
export class dialogConfirm {
  cutiForm: FormGroup;
  konfirmasi: any = [];
  chart: any = [];
  notif: any = [];
  currentUnixTime = Date.now();
  tokenFcm: String;
  dialogKurir: any = [];
  UrlToken = 'https://fcm.googleapis.com/fcm/send';

  statusTmp: any;

  DataKurir: any = [];
  finaldata: any = [];
  sale: any = [];

  filteredOptions: Observable<ikurir[]>;
  options: ikurir[] = [];
  value2: any;
  ModelCuti: any = [];
  tmpdata: any[] = [];
  uid: any;
  constructor(
    http: HttpClient,
    private API: ApiService,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<dialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }


  msg = {
    "notification": {
      "image": "https://firebasestorage.googleapis.com/v0/b/pasarpagisegar.appspot.com/o/ic_launcher_foreground.png?alt=media&token=d1a5e308-f0bf-45a7-8f4b-b09e47187c19",
      "color": "#990000",
      "click_action": "NOTIFICATION_CLICK"
    },
    "data": {
      "keyname": this.data.penjualan.id
    },
    // "to": "dn87btsM7Bg:APA91bHlYCHdJJNXVJcoWsq_jUtcumE8KJ3M2ir-UQweGSbeXouEFhHQMjfjn4SLgkiOY06Bar9Q0a4IWiZGUClZHJaK6nF7787eGJRjay6f3FNUIvsD7RdiIDXM_RibXb1rb8YTuQaG"
  };

  ngOnInit(): void {
    // console.log(this.data);

    if (this.data.penjualan.kurir.vendorKurir === "") {
      this.DataKurir = [];
    } else {
      this.DataKurir.push(this.data.penjualan.kurir);
    }

    this.chart = this.data.penjualan.chart;
    this.konfirmasi = this.data;
    this.notif = this.data.notif;

    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[0].judul;
    var isi = this.notif[0].isi;
    var unixTime = Date.now();


    this.tokenFcm = this.data.penjualan.tokenFcm;

    // this.cutiForm = new FormGroup({
    //   nip: new FormControl(''),
    // });
  }




  LihatGbr(e) {
    var win = window.open(e, '_blank');
    win.focus();
  }

  onNew() {
    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[0].judul;
    var isi = this.notif[0].isi;
    this.msg["to"] = this.tokenFcm;
    this.msg.notification["title"] = title;
    this.msg.notification["body"] = isi;
    var unixTime = Date.now();
    this.db.database.ref('debug/Penjualan/' + idInduk + '/' + id + '/status').set('waiting');

    this.API.confirmToken(this.msg).subscribe(result => {
      console.log(result);
    });

    this.db.database.ref('debug/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
      'title': title,
      'isi': isi,
      'kodechart': id,
      'tanggal': Date.now().valueOf(),
    });
    this.dialogRef.close();
  }

  onWaiting() {
    if (this.data.penjualan.statusbayar.toLowerCase() === "transfer") {
      var idInduk = this.data.penjualan.idInduk;
      var id = this.data.penjualan.id;
      var jlh = this.data.penjualan.chart;
      var title = this.notif[1].judul;
      var isi = this.notif[1].isi;
      this.msg["to"] = this.tokenFcm;
      this.msg.notification["title"] = title;
      this.msg.notification["body"] = isi;
      var unixTime = Date.now();
      this.db.database.ref('debug/Penjualan/' + idInduk + '/' + id + '/status').set('paid');

      this.API.confirmToken(this.msg).subscribe(result => {
        console.log(result);
      });
      this.db.database.ref('debug/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
        'title': title,
        'isi': isi,
        'kodechart': id,
        'tanggal': Date.now().valueOf(),
      });
      this.dialogRef.close();
    } else {
      var idInduk = this.data.penjualan.idInduk;
      var id = this.data.penjualan.id;
      var jlh = this.data.penjualan.chart;
      var title = this.notif[5].judul;
      var isi = this.notif[5].isi;
      this.msg["to"] = this.tokenFcm;
      this.msg.notification["title"] = title;
      this.msg.notification["body"] = isi;
      var unixTime = Date.now();
      this.db.database.ref('debug/Penjualan/' + idInduk + '/' + id + '/status').set('paid');

      this.API.confirmToken(this.msg).subscribe(result => {
        console.log(result);
      });
      this.db.database.ref('debug/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
        'title': title,
        'isi': isi,
        'kodechart': id,
        'tanggal': Date.now().valueOf(),
      });
      this.dialogRef.close();
    }
  }

  wa() {
    window.open("https://wa.me/" + this.DataKurir[0].kontak + "?text=Nama Penerima :" + this.data.penjualan.pengiriman.penerima + ",%0aAlamat Penerima :" + this.data.penjualan.pengiriman.alamat + "," + this.data.penjualan.pengiriman.kecamatan + "-" + this.data.penjualan.pengiriman.kelurahan + ",%0aKodepos :" + this.data.penjualan.pengiriman.kodePos + ",%0aKontak :" + this.data.penjualan.pengiriman.kontak + ",%0aBELANJA SEGAR%0a%0a" + "http://www.google.com/maps/place/" + this.data.penjualan.pengiriman.lat + "," + this.data.penjualan.pengiriman.lng, "_blank");
    this.dialogRef.close();
  }

  async onPaid() {
    // alert('Terima kasih telah melakukan pembayaran');
    // console.log(this.data.penjualan.pengiriman.lat);
    // console.log(this.data.penjualan.pengiriman.lng);
    // console.log(this.DataKurir);
    // console.log(this.data.penjualan);
    // console.log(this.data);


    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[2].judul;
    var isi = this.notif[2].isi;
    this.msg["to"] = this.tokenFcm;
    this.msg.notification["title"] = title;
    this.msg.notification["body"] = isi;

    this.wa();
    // window.open("https://wa.me/" + this.DataKurir[0].kontak + "?text=Nama Penerima :" + this.data.penjualan.pengiriman.penerima + ",%0aAlamat Penerima :" + this.data.penjualan.pengiriman.alamat + "," + this.data.penjualan.pengiriman.kecamatan + "-" + this.data.penjualan.pengiriman.kelurahan + ",%0aKodepos :" + this.data.penjualan.pengiriman.kodePos + ",%0aKontak :" + this.data.penjualan.pengiriman.kontak + ",%0aBELANJA SEGAR%0a%0a" + "http://www.google.com/maps/place/" +this.data.penjualan.pengiriman.lat + "," + this.data.penjualan.pengiriman.lng, "_blank");


    if (this.DataKurir.length <= 0) {
      alert('Kurir Kosong, Input kurir !');
      return;
    }

    this.db.database.ref('debug/Penjualan/' + idInduk + '/' + id + '/status').set('done');

    let temp = this.chart.reduce((a, c) => {
      let filtered = a.filter(el => el.idProduct === c.idProduct);
      if (filtered.length > 0) {
        a[a.indexOf(filtered[0])].harga[0].jumlah += +c.harga[0].jumlah;
      } else {
        a.push(c);
      }
      return a;
    }, []);

    let j = 0;
    temp.forEach(data => {
      console.log(data);
      if (data.harga[j].satuan === 'Kg') {
        this.db.database.ref(`debug/sale/${data.idProduct}/${this.currentUnixTime}-${j}`).set(
          {
            'jlh': data.harga[j].jumlah,
            'satuan': data.harga[j].satuan,
            'jlhgram': data.harga[j].jumlah * 1000,
            'satuangram': 'gram',
          });
      } else if (data.harga[j].satuan === 'ons') {
        this.db.database.ref(`debug/sale/${data.idProduct}/${this.currentUnixTime}-${j}`).set(
          {
            'jlh': data.harga[j].jumlah,
            'satuan': data.harga[j].satuan,
            'jlhgram': data.harga[j].jumlah * 100,
            'satuangram': 'gram',
          });
      } else {
        this.db.database.ref(`debug/sale/${data.idProduct}/${this.currentUnixTime}-${j}`).set(
          {
            'jlh': data.harga[j].jumlah,
            'satuan': data.harga[j].satuan,
            'jlhgram': data.harga[j].jumlah,
            'satuangram': 'gram',
          });
      }
      j++;
    });

    this.API.confirmToken(this.msg).subscribe(result => {
      console.log(result);
    });

    let i = 0;

    this.db.database.ref('debug/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
      'title': title,
      'isi': isi,
      'kodechart': id,
      'tanggal': Date.now().valueOf(),
    });

    this.db.database.ref('debug/Penjualan/' + idInduk + '/' + id + '/kurir').set({
      biayaKirim: this.DataKurir[0].biayaKirim,
      estimasi: this.DataKurir[0].estimasi,
      jarak: this.DataKurir[0].jarak,
      kontak: this.DataKurir[0].kontak,
      nama: this.DataKurir[0].nama,
      noResi: this.DataKurir[0].noResi,
      vendorKurir: this.DataKurir[0].vendorKurir,
    });
    this.dialogRef.close();
  }

  onDone() {
    // alert('Terima kasih telah melakukan pembayaran');
    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[3].judul;
    var isi = this.notif[3].isi;
    this.msg["to"] = this.tokenFcm;
    this.msg.notification["title"] = title;
    this.msg.notification["body"] = isi;

    this.db.database.ref('debug/Penjualan/' + idInduk + '/' + id + '/status').set('confirm');

    this.API.confirmToken(this.msg).subscribe(result => {
      console.log(result);
    });

    this.db.database.ref('debug/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
      'title': title,
      'isi': isi,
      'kodechart': id,
      'tanggal': Date.now().valueOf(),
    });
    // this.wa();
    this.dialogRef.close();
  }

  onDelivered() {
    // alert('Terima kasih telah melakukan pembayaran');
    var idInduk = this.data.penjualan.idInduk;
    var id = this.data.penjualan.id;
    var jlh = this.data.penjualan.chart;
    var title = this.notif[4].judul;
    var isi = this.notif[4].isi;
    this.msg["to"] = this.tokenFcm;
    this.msg.notification["title"] = title;
    this.msg.notification["body"] = isi;

    this.db.database.ref('debug/Penjualan/' + idInduk + '/' + id + '/status').set('finish');

    this.API.confirmToken(this.msg).subscribe(result => {
    });

    // this.msgKurir["to"] = 'eK_FQucuAyU:APA91bFrpWIz0d7sJoVY2omESL4jcOnVtZwoN9ufJtzFz_PjPWnx4X4RqLpmnZ0J8B-Ta4XdDWHjfITIw5sXixE7yXRF4XUcbsx0yzDtUniRprFJkfUkDwV3hegD2oZr85IgTp_dkKYb';
    // this.msgKurir.notification["title"] = 'Pesanan Baru';
    // this.msgKurir.notification["body"] = 'Pesanan baru dari '+ this.data.penjualan.nama;

    // this.API.confirmKurir(this.msgKurir).subscribe(result => {
    //   console.log(result);
    // });

    // this.chart.forEach(data => {
    //   this.db.database.ref('debug/sale/' + data.penjualan.idProduct + '/' + this.currentUnixTime).set({
    //     'jlh': data.jlh,
    //   });
    // });

    this.db.database.ref('debug/notifikasi/' + idInduk + '/' + this.currentUnixTime).set({
      'title': title,
      'isi': isi,
      'kodechart': id,
      'tanggal': Date.now().valueOf(),
    });
    this.dialogRef.close();

  }

  onDismiss() {
    this.dialogRef.close();
  }

  onCetak() {
    console.log();
    const dialogRef = this.dialog.open(printStruckDialog, {
      width: '80%',
      height: '70%',
      data: {
        penjualan: this.data.penjualan,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }

  findIndexToUpdate(newItem) {
    return newItem.id === this;
  }

  Kurir(item) {
    if (this.DataKurir.length === 0) {
      this.dialogKurir = this.dialog.open(dialogKurir, {
        width: '40%',
        height: '70%',
        data: {
          action: 'new',
          kurir: item,
        }
      });
    } else {
      this.dialogKurir = this.dialog.open(dialogKurir, {
        width: '40%',
        height: '70%',
        data: {
          action: 'edit',
          Kurir: this.DataKurir,
        }
      });
    }

    this.dialogKurir.afterClosed().subscribe(result => {
      this.DataKurir = [];
      // console.log(result);
      this.DataKurir.push({
        'id': 0,
        'biayaKirim': result.data.biayaKirim,
        'estimasi': result.data.estimasi,
        'jarak': result.data.jarak,
        'kontak': result.data.kontak,
        'nama': result.data.nama,
        'noResi': result.data.noResi,
        'vendorKurir': result.data.vendorKurir,
      });
    });
  }
}

@Component({
  selector: 'struck-dialog',
  templateUrl: './printStruckdialog.html',
  styleUrls: ['kurirdialog/kurir.component.scss']
})
export class printStruckDialog {
  today: number = Date.now();
  modelKurir: any = [];
  title: string = '';
  tombol: string = '';

  constructor(
    http: HttpClient,
    private API: ApiService,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<dialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  Simpan() {
    this.dialogRef.close({
      'action': this.data.action,
      'data': this.modelKurir,
    });
  }

  Tutup() {
    this.dialogRef.close({
      'action': 'close',
    })
  }
}

@Component({
  selector: 'kurir-dialog',
  templateUrl: 'kurirdialog/kurir-dialog.html',
  styleUrls: ['kurirdialog/kurir.component.scss']
})
export class dialogKurir {
  kurirform: FormGroup;
  modelKurir: any = [];
  title: string = '';
  tombol: string = '';

  filteredOptions: Observable<ikurir[]>;
  options: ikurir[] = [];
  value2: any;
  ModelCuti: any = [];
  tmpdata: any[] = [];
  uid: any;

  constructor(
    http: HttpClient,
    private API: ApiService,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<dialogConfirm>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data);
    this.getKurir();
    if (this.data.action === 'new') {
      this.modelKurir = this.data.kurir;
      // console.log(this.modelKurir);
      this.title = '';
      this.tombol = 'Simpan';
    } else {
      this.modelKurir = this.data.Kurir[0];
      this.title = 'Edit';
      this.tombol = 'Edit';
    }

    this.kurirform = new FormGroup({
      vendorKurir: new FormControl('', [Validators.required]),
      nama: new FormControl('', [Validators.required]),
      estimasi: new FormControl('', [Validators.required]),
      kontak: new FormControl('', [Validators.required]),
      noResi: new FormControl(''),
    });

    // this.kurirform = new FormGroup({
    //   nama: new FormControl('', [Validators.required]),
    // })
  }

  async getKurir() {
    this.db.list('/debug/kurirlist/').snapshotChanges().subscribe(kurirs => {
      this.options = [];
      kurirs.forEach(e => {
        this.options.push(getListkurir(e));
      });
      this.filteredOptions = this.kurirform.controls['nama'].valueChanges
        .pipe(
          startWith({} as ikurir),
          map(user => user && typeof user === 'object' ? user.nama : user),
          map((name: string) => name ? this.filter(name) : this.options.slice())
        );
    });
  }

  displayFn(user: ikurir): string {
    return user ? user.nama : '';
  }

  filter(name: string): ikurir[] {
    return this.options.filter(option =>
      option.nama.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  private _filter(value: string): ikurir[] {
    console.log(value);
    const filterValue = value;
    const results = this.options.filter(option => option.nama.includes(filterValue));

    return results;
  }

  _allowSelection(option: string): { [className: string]: boolean } {
    return {
      'no-data': option === 'No data',
    };
  }

  onSelectionChanged(event: MatAutocompleteSelectedEvent) {
    this.tmpdata = [];
    this.uid = '';
    let pil = event.option.value;
    console.log(pil);
    this.modelKurir.kontak = pil.kontak;
    // this.tmpdata = this.options.filter((item) => {
    //   return item.kontak === pil;
    // });
  }

  Simpan() {
    // console.log(this.kurirform.controls);
    // console.log(this.modelKurir);

    this.dialogRef.close({
      'action': this.data.action,
      'data': {
        'biayaKirim': this.modelKurir.biayaKirim,
        'estimasi': this.modelKurir.estimasi,
        'jarak': this.modelKurir.jarak,
        'kontak': this.modelKurir.kontak,
        'nama': this.modelKurir.id.nama,
        'noResi': this.modelKurir.noResi,
        'vendorKurir': this.modelKurir.vendorKurir,
      },
    });
  }

  Tutup() {
    this.dialogRef.close({
      'action': 'close',
    })
  }
}


export interface MenuData {
  idInduk: string;
  id: string;
  carabayar: string;
  kurir: any[];
  nama: string;
  pengiriman: any;
  status: string;
  tgl: any;
  tokenFcm: any;
  totalbayar: number;
  chart: chart[];
  statusbayar: string;
}

export interface chart {
  idProduk: string;
  harga: number;
  jumlah: number;
  namaproduk: string;
  url: any;

}

export interface notifPembayaran {
  judul: string;
  isi: string;
}

function GetListOrder(idInduk: string, id: string, carabayar: string, kurir: any, nama: string, pengiriman: any, tgl: any,
  status: string, tokenFcm: any, totalbayar: number, chart: chart[], statusbayar: string): MenuData {

  return {
    idInduk: idInduk.toString(),
    id: id.toString(),
    carabayar: carabayar,
    kurir: kurir,
    nama: nama,
    pengiriman: pengiriman,
    tgl: tgl,
    status: status,
    tokenFcm: tokenFcm,
    totalbayar: totalbayar,
    chart: chart,
    statusbayar: statusbayar,
  };
}

function getListNotifPembayaran(judul: string, isi: string): notifPembayaran {
  return {
    judul: judul,
    isi: isi,
  };
}
function getListkurir(data: any): ikurir {
  return {
    id: data.key,
    nama: data.payload.val()['nama'],
    kontak: data.payload.val()['kontak'],
  }
}
export interface ikurir {
  id: string;
  nama: string;
  kontak: string;
}
export const COMPONENT_LIST = [
  Order,
  dialogConfirm,
  dialogKurir,
  printDialog,
  printStruckDialog
]
