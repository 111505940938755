import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MessagingService } from './services/messaging.service';

import { environment } from '../environments/environment';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { DaftarSlideModule } from 'app/main/slide/slide.module';
import { DaftarMenuModule } from 'app/main/daftarmenu/daftarmenu.module';
import { OrderModule } from './main/order/order.module';
import { PengaturanModule } from './main/pengaturan/pengaturan.module';
import { AsyncPipe } from '@angular/common';
import { Login2Module } from 'app/main/login-2/login-2.module';
import { LaporanModule } from './main/laporan/laporan.module';
import { DaftarHargaModule } from './main/daftar-harga/daftar-harga.module';
import { MasterSatuanModule } from './main/master-satuan/master-satuan.module';
import { KurirListModule } from './main/kurir-list/kurir-list.module';
import { DaftarKonsumenModule } from './main/daftar-konsumen/daftar-konsumen.module';

const appRoutes: Routes = [
    {
        path: '**',
        redirectTo: 'login'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),

        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireMessagingModule,
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        AngularFireStorageModule,
        
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        DaftarSlideModule,
        DaftarMenuModule,
        OrderModule,
        Login2Module,
        PengaturanModule,
        LaporanModule,
        DaftarHargaModule,
        MasterSatuanModule,
        KurirListModule,
        DaftarKonsumenModule,
    ],
    providers: [MessagingService, AsyncPipe],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
