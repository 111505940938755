export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'PENGUMUMAN': {
                'TITLE': 'Pengumuman',
                'BADGE': '25'
            },
            'SLIDE': {
                'TITLE': 'Slide',
                'BADGE': '25'
            },
            'DAFTARMENU': {
                'TITLE': 'Product',
                'BADGE': '25'
            },
            'ORDER': {
                'TITLE': 'Order',
                'BADGE': '25'
            },
            'LISTKURIR' : {
                'TITLE': 'List Kurir',
                'BADGE': '25'
            },
            'KONSUMEN' :{
                'TITLE': 'Konsumen',
                'BADGE': '25'
            },
            'LAPORAN': {
                'TITLE': 'Laporan',
                'BADGE': '25'
            },
            'PENGATURAN': {
                'TITLE': 'Pengaturan',
                'BADGE': '25'
            },
            'PENGATURAN1': {
                'TITLE': 'Pengaturan',
                'BADGE': '25'
            },
            'MASTERSATUAN' : {
                'TITLE': 'Master Satuan',
                'BADGE': '25'
            },
            'DAFTARHARGA': {
                'TITLE': 'Daftar Harga',
                'BADGE': '25'
            },
            'DESKRIPSI': {
                'TITLE': 'Desk. Pembayaran',
                'BADGE': '25',
            },
            'NOTIFIKASI': {
                'TITLE': 'Notification',
                'BADGE': '25'
            },
            'KELUAR': {
                'TITLE': 'Keluar',
                'BADGE': '25'
            }
        }
    }
};
