import { FuseNavigation } from '@fuse/types';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'Applications',
        translate: 'NAV.APPLICATIONS',
        type: 'group',
        children: [
            // {
            //     id       : 'png',
            //     title    : 'pengumuman',
            //     translate: 'NAV.PENGUMUMAN.TITLE',
            //     type     : 'item',
            //     icon     : 'list',
            //     url      : '/pengumuman',
            //     // badge    : {
            //     //     title    : '25',
            //     //     translate: 'NAV.SAMPLE.BADGE',
            //     //     bg       : '#F44336',
            //     //     fg       : '#FFFFFF'
            //     // }
            // },
            {
                id: 'menu',
                title: 'Daftar Menu',
                translate: 'NAV.DAFTARMENU.TITLE',
                type: 'item',
                icon: 'list',
                url: '/listmenu',
                // badge    : {
                //     title    : '25',
                //     translate: 'NAV.DETAIL.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }

            },
            {
                id: 'slide',
                title: 'Slide',
                translate: 'NAV.SLIDE.TITLE',
                type: 'item',
                icon: 'list',
                url: '/slide',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id: 'order',
                title: 'Order',
                translate: 'NAV.ORDER.TITLE',
                type: 'item',
                icon: 'list',
                url: '/order',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id: 'kurirlist',
                title: 'List Kurir',
                translate: 'NAV.LISTKURIR.TITLE',
                type: 'item',
                icon: 'list',
                url: '/kurir-list',
            },
            {
                id: 'daftarkonsumen',
                title: 'Konsumen',
                translate: 'NAV.KONSUMEN.TITLE',
                type: 'item',
                icon: 'list',
                url: '/daftar-konsumen',
            },
            {
                id: 'laporan',
                title: 'Laporan',
                translate: 'NAV.LAPORAN.TITLE',
                type: 'item',
                icon: 'list',
                url: '/laporan',
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
            {
                id: 'pengaturan',
                title: 'Pengaturan',
                translate: 'NAV.PENGATURAN.TITLE',
                type: 'collapsable',
                icon: 'list',
                children: [
                    {
                        id: 'pengaturan',
                        title: 'Pengaturan',
                        translate: 'NAV.PENGATURAN1.TITLE',
                        type: 'item',
                        url: '/pengaturan',
                    },
                    {
                        id: 'mastersatuan',
                        title: 'Master Satuan',
                        translate: 'NAV.MASTERSATUAN.TITLE',
                        type: 'item',
                        url: '/settings/master-satuan',
                    },
                    {
                        id: 'daftarharga',
                        title: 'Daftar Harga',
                        translate: 'NAV.DAFTARHARGA.TITLE',
                        type: 'item',
                        url: '/daftar-harga',
                    },
                ]
            },
            {
                id: 'keluar',
                title: 'Keluar',
                translate: 'NAV.KELUAR.TITLE',
                type: 'item',
                icon: 'settings_power',
                url: '/login',
                function: () => {
                    localStorage.clear();
                    this.AngularFireAuth.auth.signOut().then(() => {
                        this.router.navigate(['/login']);
                    });
                },
                // badge    : {
                //     title    : '1',
                //     translate: 'NAV.SAMPLE.BADGE',
                //     bg       : '#F44336',
                //     fg       : '#FFFFFF'
                // }
            },
        ]
    }

];
