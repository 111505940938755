import { Component, ViewChild, Inject, OnInit, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Observable } from 'rxjs';
import { finalize, subscribeOn } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FuseSplashScreenService } from '../../../@fuse/services/splash-screen.service';
import { OverlayService } from '../../overlay/overlay.module';
import { FormControl, Validators, Form, NgForm } from '@angular/forms';
import { ProgressSpinnerComponent } from '../../progress-spinner/progress-spinner.module';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
//import { formArrayNameProvider } from '@angular/forms/src/directives/reactive_directives/form_group_name';
//import undefined = require('firebase/empty-import');
declare var jQuery: any;
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';

@Component({
  selector: 'daftarmenu',
  templateUrl: './daftarmenu.component.html',
  styleUrls: ['./daftarmenu.component.scss']
})
export class DaftarMenu {
  ref: any = [];
  items: any[];
  selectedRowIndex = [];
  displayedColumns = ['id', 'category'];
  loadRef: any = [];
  dialogRef: any = [];
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  auth: any;

  listmenu: MenuData[] = [];
  dataSource: MatTableDataSource<MenuData>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  async ngOnInit() {
    this.auth = await JSON.parse(localStorage.getItem("AUTH"));
    if (this.auth === null) {
      this.router.navigate(['/login']);
    }
    this.loadSpinner();
    this.db.list('/debug/kategori')
      .snapshotChanges()
      .subscribe(items => {
        this.listmenu = [];
        items.forEach(item => {
          // console.log(item.key);
          //console.log(item.payload.val());
          // console.log(item.payload.val()['category']);
          this.listmenu.push(GetListMenu(item.payload.val()['nama'], item.key, item.payload.val()['image'], item.payload.val()['filename'], item.payload.val()['produk']));
        });
        this.dataSource = new MatTableDataSource(this.listmenu);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.closeSpinner();
      });
    let oldToken = await localStorage.getItem('FCM');
    this.db.database.ref('debug/server/' + this.auth.uid).set({
      'token': oldToken,
    });
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.category + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref('debug/kategori/' + item.id).remove();
      this.storage.ref(item.filename).delete().subscribe(() => {
        this.closeSpinner();
      })
    }
  }

  getRecord(row) {
    this.dialogRef = this.dialog.open(DialogDaftarmenu, {
      data: row,
    });
  }

  getList(item) {
    this.dialogRef = this.dialog.open(DialogDaftarProduk, {
      height: '95%',
      width: '99%',
      data: item,
    });
  }

  addProduct() {
    this.dialogRef = this.dialog.open(DialogDaftarmenu, {
    });
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}

@Component({
  selector: 'inputProdukImage-dialog',
  templateUrl: 'inputProdukImage-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogProdukImage {
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = [];
  ListImage = [];
  imageChangedEvent: any = '';
  gambar: any;
  croppedImage: any = '';
  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,

    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;
  ngOnInit() {
    if (this.data !== null) {
      this.gambar = this.data.image;
    }
  }
  openfile() {
    jQuery('#imgupload').trigger('click');
  }
  getFileAttachment(event: any) {
    this.imageChangedEvent = event;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

}


@Component({
  selector: 'daftarharga-dialog',
  templateUrl: 'daftarharga-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogDaftarharga {
  satuan: Satuan[] = [];

  flag: boolean = true;
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  harga: any = [];
  spinnerWithoutBackdrop = false;

  // { value: 'Kg', viewValue: 'Kg' },
  // { value: 'gr', viewValue: 'Gram' },
  // { value: 'ons', viewValue: 'ons' },
  // { value: 'Ikat', viewValue: 'Ikat' },
  // { value: 'ml', viewValue: 'Mili Liter' },
  // { value: 'L', viewValue: 'Liter' },
  // { value: 'Lusin', viewValue: 'Lusin' },
  // { value: 'Botol', viewValue: 'Botol' },
  // { value: 'Kotak', viewValue: 'Kotak' }
  // satuan: Satuan[] = [
  //   { value: 'Kg', viewValue: 'Kg' },
  //   { value: 'Gr', viewValue: 'Gram' },
  //   { value: 'ons', viewValue: 'ons' },
  //   { value: 'Ikat', viewValue: 'Ikat' },
  //   { value: 'Lusin', viewValue: 'Lusin' },
  //   { value: 'Bungkus', viewValue: 'Bungkus' },
  //   { value: 'Buah', viewValue: 'Buah' },
  //   { value: 'Botol', viewValue: 'Botol' },
  //   { value: 'Kotak', viewValue: 'Kotak' }
  // ];

  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.loadSatuan();
  }

  loadSatuan() {
    this.db.list('/debug/satuan/').snapshotChanges().subscribe(item => {
      this.satuan = [];
      item.forEach(e => {
        this.satuan.push(GetSatuan(e.key, e.payload.val()['viewValue']));
      });
    })
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

}

@Component({
  selector: 'inputProduk-dialog',
  templateUrl: 'inputProduk-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogInputProduk {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  donwloadLink: any = [];
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  flagedit: boolean = false;
  key: any;
  imageChangedEvent: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  modelProduct: any = {};
  ListImage: any = [];
  ListImageEdit: any = [];
  ListMenu: any = [];
  ListHarga: any = [];
  ListImageAsal: any = [];
  namaKategori;
  kategoriControl: FormControl = new FormControl();

  constructor(
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<DialogProdukImage>,
    public dialogInput: MatDialogRef<DialogInputProduk>,
    public dialogInputharga: MatDialogRef<DialogDaftarharga>,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    iconRegistry.addSvgIcon(
      'photo',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add_photo.svg'));
    iconRegistry.addSvgIcon(
      'close',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg'));
  }
  // { value: 'ml', viewValue: 'Mili Liter' },
  // { value: 'L', viewValue: 'Liter' },
  foods: Food[] = [
    { value: 'Kg', viewValue: 'Kg' },
    { value: 'Gr', viewValue: 'Gram' },
    { value: 'ons', viewValue: 'ons' },
    { value: 'Ikat', viewValue: 'Ikat' },
    { value: 'Lusin', viewValue: 'Lusin' },
    { value: 'Bungkus', viewValue: 'Bungkus' },
    { value: 'Buah', viewValue: 'Buah' },
    { value: 'Botol', viewValue: 'Botol' },
    { value: 'Kotak', viewValue: 'Kotak' }
  ];
  ngOnInit() {

    if (this.data.type === 'edit') {
      this.flagedit = true;
      this.modelProduct.nama = this.data.data.nama;
      this.modelProduct.sub = this.data.data.sub;
      this.modelProduct.ukuran = this.data.data.ukuran;
      this.modelProduct.satuan = this.data.data.satuan;
      this.modelProduct.harga = this.data.data.harga;
      this.modelProduct.disc = this.data.data.disc;
      this.modelProduct.kandungan = this.data.data.kandungan;
      this.modelProduct.type = this.data.data.type;
      this.modelProduct.keterangan = this.data.data.keterangan;
      this.ListHarga = this.data.data.harga;
      if (this.data.data.listImage !== null && this.data.data.listImage.length > 0) {
        this.data.data.listImage.forEach((item) => {
          this.ListImage.push({
            url: item.url,
            asal: 1,
          });
        });
      }

    } else {
      this.namaKategori = this.data.data.category;
      this.key = this.data.data.id
      console.log(this.key);
    }

  }

  clickNew() {
    this.dialogRef = this.dialog.open(DialogProdukImage, {

    });
    this.dialogRef.afterClosed().subscribe(result => {
      if (result !== '') {
        let gbr = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
          + result.base64);
        this.ListImage.push({ asal: 0, file: result.file, url: result.base64 });
        this.ListImageAsal.push(result.file);
      }

    });
  }

  addHarga() {
    this.dialogInputharga = this.dialog.open(DialogDaftarharga, {
      width: '15%',
    });
    this.dialogInputharga.afterClosed().subscribe(result => {
      if (result !== '') {
        this.ListHarga.push({ ukuran: result.ukuran, ukuranSampai: result.ukuranSampai, satuan: result.satuan, nominal: result.nominal.toString() });
      }
    });
  }

  openfile(url) {
    window.open(url);
  }

  RemoveMore(index, item) {
    this.ListImage.splice(index, 1);
    var str = item.url;
    var pecah = str.split("?");
    var pecah2 = pecah[0].split("%2F");
    this.ListImageEdit.push(pecah2[1]);
  }

  Removeharga(index) {
    this.ListHarga.splice(index, 1);
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  async delImage() {
    if (this.flagedit) {
      this.ListImageEdit.forEach((item) => {
        this.storage.ref(this.namaKategori + '/' + item).delete();
      });
      return true;
    } else {
      return true;
    }
  }

  async getImage() {
    if (this.flagedit) {
      this.ListImage.forEach((item) => {
        if (item.asal == 1) {
          this.donwloadLink.push({
            url: item.url,
          });
        }
      });
    }
  }

  // simpan() {
  //   console.log(this.modelProduct.sub);
  // }

  async editCategory() {
    if (this.modelProduct.nama == undefined) {
      alert('Nama Produk harus di isi');
      return
    }
    // if (this.modelProduct.sub == undefined) {
    //   alert('Sub Judul harus di isi');
    //   return
    // }
    // if (this.modelProduct.keterangan == undefined) {
    //   alert('Keterangan harus di isi');
    //   return
    // }
    // if (this.modelProduct.kandungan == undefined) {
    //   alert('Kandungan harus di isi');
    //   return
    // }

    if (this.ListHarga.length < 1) {
      alert('harga minimal 1');
      return
    }

    // if (this.ListImage.length < 1) {
    //   alert('Image Minimal 1');
    //   return
    // }

    this.loadSpinner();
    await this.delImage();
    await this.getImage();
    var currentUnixTime = Date.now();
    const allPercentage: Observable<number>[] = [];

    if (this.data.type !== 'edit') {
      if (this.ListImage.length <= 0) {
        this.db.database.ref('/debug/kategori/' + this.key + '/produk/' + currentUnixTime + '/').set(
          {
            'nama': this.modelProduct.nama.toString(),
            'sub': this.modelProduct.sub === undefined ? '' : this.modelProduct.sub.toString(),
            'keterangan': this.modelProduct.keterangan === undefined ? '' : this.modelProduct.keterangan.toString(),
            'kandungan': this.modelProduct.kandungan === undefined ? '' : this.modelProduct.kandungan.toString(),
            'harga': this.ListHarga < 0 ? 0 : this.ListHarga,
            'disc': this.modelProduct.disc === undefined ? 0 : this.modelProduct.disc,
            'image': '',
            'publish': true,
            'type': this.modelProduct.type === undefined ? 'normal' : this.modelProduct.type,
          }
        ).then(() => {
          this.closeSpinner();
          this.dialogInput.close();
        });
      } else if (this.ListImageAsal.length > 0) {
        for (const { item, index } of this.ListImage.map((item, index) => ({ item, index }))) {
          const filePath = this.namaKategori + '/' + this.namaKategori + '_' + currentUnixTime + '_' + index;
          const fileRef = this.storage.ref(filePath);
          if (item.asal == 0) {
            const task = this.storage.upload(filePath, item.file);
            const _percentage$ = task.percentageChanges();
            allPercentage.push(_percentage$);

            // observe percentage changes
            this.uploadPercent = task.percentageChanges();

            // get notified when the download URL is available
            task.snapshotChanges().pipe(
              finalize(() => {
                fileRef.getDownloadURL().subscribe(url => {
                  this.donwloadLink.push({ 'url': url });
                  if (this.ListImage.length === this.donwloadLink.length) {
                    this.db.database.ref('/debug/kategori/' + this.key + '/produk/' + currentUnixTime).set(
                      {
                        'nama': this.modelProduct.nama.toString(),
                        'sub': this.modelProduct.sub === undefined ? '' : this.modelProduct.sub.toString(),
                        'keterangan': this.modelProduct.keterangan === undefined ? '' : this.modelProduct.keterangan.toString(),
                        'kandungan': this.modelProduct.kandungan === undefined ? '' : this.modelProduct.kandungan.toString(),
                        'harga': this.ListHarga < 0 ? 0 : this.ListHarga,
                        'disc': this.modelProduct.disc === undefined ? 0 : this.modelProduct.disc,
                        'image': this.donwloadLink,
                        'publish': true,
                        'type': this.modelProduct.type === undefined ? 'normal' : this.modelProduct.type,
                      }
                    ).then(() => {
                      this.closeSpinner();
                      this.dialogInput.close();
                    });
                  }
                }
                );
              })
            ).subscribe();
          }
        }
      }
    } else {
      // console.log(this.ListImage);
      // console.log(this.donwloadLink);
      // console.log(this.ListImageAsal);
      if (this.ListImageAsal.length > 0) {
        for (const { item, index } of this.ListImage.map((item, index) => ({ item, index }))) {
          const filePath = this.namaKategori + '/' + this.namaKategori + '_' + currentUnixTime + '_' + index;
          const fileRef = this.storage.ref(filePath);
          if (item.asal == 0) {
            const task = this.storage.upload(filePath, item.file);
            const _percentage$ = task.percentageChanges();
            allPercentage.push(_percentage$);

            // observe percentage changes
            this.uploadPercent = task.percentageChanges();

            // get notified when the download URL is available
            task.snapshotChanges().pipe(
              finalize(() => {
                fileRef.getDownloadURL().subscribe(url => {
                  this.donwloadLink.push({ 'url': url });
                  if (this.ListImage.length === this.donwloadLink.length) {
                    this.db.database.ref('/debug/kategori/' + this.data.data.key + '/produk/' + this.data.data.id).set(
                      {
                        'nama': this.modelProduct.nama.toString(),
                        'sub': this.modelProduct.sub === undefined ? '' : this.modelProduct.sub.toString(),
                        'keterangan': this.modelProduct.keterangan === undefined ? '' : this.modelProduct.keterangan.toString(),
                        'kandungan': this.modelProduct.kandungan === undefined ? '' : this.modelProduct.kandungan.toString(),
                        'harga': this.ListHarga < 0 ? 0 : this.ListHarga,
                        'disc': this.modelProduct.disc === undefined ? 0 : this.modelProduct.disc,
                        'image': this.donwloadLink,
                        'publish': this.data.data.publish,
                        'type': this.modelProduct.type === undefined ? 'normal' : this.modelProduct.type,
                      }
                    ).then(() => {
                      this.closeSpinner();
                      this.dialogInput.close();
                    });
                  }
                }
                );
              })
            ).subscribe();
          }
        }
      } else {
        this.db.database.ref('/debug/kategori/' + this.data.data.key + '/produk/' + this.data.data.id).set(
          {
            'nama': this.modelProduct.nama.toString(),
            'sub': this.modelProduct.sub === undefined ? '' : this.modelProduct.sub.toString(),
            'keterangan': this.modelProduct.keterangan === undefined ? '' : this.modelProduct.keterangan.toString(),
            'kandungan': this.modelProduct.kandungan === undefined ? '' : this.modelProduct.kandungan.toString(),
            'harga': this.ListHarga < 0 ? 0 : this.ListHarga,
            'disc': this.modelProduct.disc === undefined ? 0 : this.modelProduct.disc,
            'image': this.donwloadLink,
            'publish': this.data.data.publish,
            'type': this.modelProduct.type === undefined ? 'normal' : this.modelProduct.type,
          }
        ).then(() => {
          this.closeSpinner();
          this.dialogInput.close();
        });
      }
    }
  }
}

@Component({
  selector: 'daftarproduct-dialog',
  templateUrl: 'daftarproduk-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogDaftarProduk {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  selectedRowIndex: string;
  listproduk: ProdukData[] = [];
  dialogRef: any = [];
  dataSource: MatTableDataSource<ProdukData>;


  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: false }) imageCropper: ImageCropperComponent;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() {
    this.kategori = this.data.category.toUpperCase();
    this.loadSpinner();
    this.db.list('/debug/kategori/' + this.data.id + '/produk')
      .snapshotChanges()
      .subscribe(items => {
        this.listproduk = [];
        items.forEach(item => {
          if (item.payload.val()['image'] !== undefined && item.payload.val()['image'].length > 0) {
            this.listproduk.push(
              GetListProduk(
                item.key,
                item.payload.val()['nama'],
                item.payload.val()['sub'],
                item.payload.val()['kandungan'],
                item.payload.val()['keterangan'],
                item.payload.val()['image'][0]['url'],
                item.payload.val()['type'],
                item.payload.val()['image'],
                item.payload.val()['harga'],
                item.payload.val()['disc'],
                item.payload.val()['publish'],
                this.data.id,
              ));
          } else {
            this.listproduk.push(
              GetListProduk(
                item.key,
                item.payload.val()['nama'],
                item.payload.val()['sub'],
                item.payload.val()['kandungan'],
                item.payload.val()['keterangan'],
                item.payload.val()['image'],
                item.payload.val()['type'],
                item.payload.val()['image'] !== undefined ? item.payload.val()['image'] : null,
                item.payload.val()['harga'],
                item.payload.val()['disc'],
                item.payload.val()['publish'],
                this.data.id,
              ));
          }
        });
        this.dataSource = new MatTableDataSource(this.listproduk);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.closeSpinner();
      });
  }

  sortAZ() {
    this.listproduk.sort((a, b) => a.nama.toLowerCase().localeCompare(b.nama.toLowerCase()));
  }

  sortZA() {
    this.listproduk.sort((a, b) => b.nama.toLowerCase().localeCompare(a.nama.toLowerCase()));
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }

  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  publish(item) {
    // console.log(item);
    this.db.database.ref('/debug/kategori/' + item.key + '/produk/' + item.id + '/publish').set(true);
  }

  unpublish(item) {
    this.db.database.ref('/debug/kategori/' + item.key + '/produk/' + item.id + '/publish').set(false);
  }

  getRecord(row) {
    this.dialogRef = this.dialog.open(DialogInputProduk, {
      height: '90%',
      width: '45%',
      data: { 'type': 'edit', 'data': row },
    });
  }


  delRecord(item) {
    var r = confirm("Yakin ingin menghapus " + item.nama + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref('debug/kategori/' + item.key + '/produk/' + item.id).remove();
      item.listImage.forEach((item) => {
        var str = item.url;
        var pecah = str.split("?");
        var pecah2 = pecah[0].split("%2F");
        this.storage.ref(pecah2).delete();
      });
    }
  }

  addProduct() {
    this.dialogRef = this.dialog.open(DialogInputProduk, {
      height: '90%',
      width: '45%',
      data: { 'type': 'baru', 'data': this.data },
    });

  }
}

@Component({
  selector: 'daftarmenu-dialog',
  templateUrl: 'daftarmenu-dialog.html',
  styleUrls: ['daftarmenu.component.scss']
})
export class DialogDaftarmenu {
  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  gambar: any;
  kategori: any;
  oldGambar: any;
  flag: boolean = true;
  key: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  constructor(
    public dialogRef: MatDialogRef<DialogDaftarmenu>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  ngOnInit() {
    if (this.data !== null) {
      this.flag = true;
      this.gambar = this.data.image;
      this.key = this.data.id;
      this.oldGambar = this.data.filename;
      this.kategori = this.data.category;

    } else {
      this.flag = false;
      this.gambar = 'https://firebasestorage.googleapis.com/v0/b/pasarpagisegar.appspot.com/o/empty.png?alt=media&token=3e5b1264-f831-4c28-9d61-2956fbcf1a42';
      this.kategori = '';
    }

  }

  openfile() {
    jQuery('#imgupload').trigger('click');
  }


  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  async editCategory() {
    if (this.gambar === '') {
      alert('Gambar belum dipilih !!!');
      return;
    }
    if (this.kategori === '') {
      alert('Kategori harus di isi !!!');
      return;
    }


    this.loadSpinner();


    // observe percentage changes
    if (this.flag) {

      if (this.croppedImage !== '') {
        this.storage.ref(this.data.filename).delete();
        // this.storage.ref(this.oldGambar).delete();
        const file = this.croppedImage;
        const filePath = this.kategori + '.webp';
        const fileRefr = this.storage.ref(filePath.toString());
        const task = this.storage.upload(filePath, file.file);
        task.snapshotChanges().pipe(
          finalize(() => {
            this.downloadURL = fileRefr.getDownloadURL()
            this.downloadURL.subscribe(url => {
              if (url) {
                this.db.database.ref('/debug/kategori/' + this.key + '/').set(
                  {
                    'nama': this.kategori.toString(),
                    'filename': filePath.toString(),
                    'image': url,
                    'produk': this.data.produk,
                  }
                ).then(() => {
                  // this.storage.ref(this.data.image).delete();
                  this.closeSpinner();
                  this.dialogRef.close();
                })
              }
            })
          })
        ).subscribe();
      } else {
        this.db.database.ref('/debug/kategori/' + this.key + '/').set(
          {
            'nama': this.kategori.toString(),
            'filename': this.data.filename,
            'image': this.data.image,
            'produk': this.data.produk,
          }
        ).then(() => {
          this.closeSpinner();
          this.dialogRef.close();
        })
      }
    } else {
      const file = this.croppedImage;
      const filePath = this.kategori + '.webp';
      const fileRefr = this.storage.ref(filePath.toString());
      const task = this.storage.upload(filePath, file.file);
      task.snapshotChanges().pipe(
        finalize(() => {
          this.downloadURL = fileRefr.getDownloadURL()
          this.downloadURL.subscribe(url => {
            if (url) {
              this.db.database.ref('/debug/kategori/').push(
                {
                  'nama': this.kategori.toString(),
                  'filename': filePath.toString(),
                  'image': url,
                  'produk': '',
                }
              ).then(() => {
                this.closeSpinner();
                this.dialogRef.close();
              })
            }
          })
        })
      )
        .subscribe()
    }
  }

  getFileAttachment(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
    this.gambar = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }


}



function GetListMenu(category: string, id: string, image: string, filename: string, produk: any): MenuData {

  return {
    id: id.toString(),
    category: category,
    image: image,
    filename: filename,
    produk: produk,
  };
}

export interface MenuData {
  id: string;
  category: string;
  image: string;
  filename: string;
  produk: any
}


function GetListProduk(id: string, nama: string, sub: string,
  kandungan: string, keterangan: string, image: string,
  type: string, listImage: any, harga: any, disc: number, publish: boolean,
  key: string): ProdukData {
  return {
    id: id.toString(),
    nama: nama,
    sub: sub,
    keterangan: keterangan,
    kandungan: kandungan,
    listImage: listImage,
    image: image,
    harga: harga,
    disc: disc,
    publish: publish,
    type: type,
    key: key,
  };
}

export interface ProdukData {
  id: string;
  nama: string;
  sub: string;
  keterangan: string;
  image: string;
  listImage: any;
  harga: any;
  disc: number;
  publish: boolean;
  kandungan: string;
  type: string;
  key: string;
}
export interface Food {
  value: string;
  viewValue: string;
}

export interface Satuan {
  value: string;
  viewValue: string;
}

function GetSatuan(value: string, viewValue: string): Satuan {
  return {
    value: value,
    viewValue: viewValue,
  };
}

export const COMPONENT_LIST = [
  DaftarMenu,
  DialogDaftarmenu,
  DialogDaftarProduk,
  DialogInputProduk,
  DialogProdukImage,
  DialogDaftarharga,

]