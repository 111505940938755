import { Component, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { fuseAnimations } from '@fuse/animations';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'master-satuan',
  templateUrl: './master-satuan.component.html',
  styleUrls: ['./master-satuan.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class MasterSatuanComponent implements OnInit {
  displayedColumns = ['nama', 'aksi'];
  listSatuan: Satuan[] = [];
  dataSource: MatTableDataSource<Satuan>;

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private af: AngularFireAuth,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit() {
    this.loadSatuan();
  }

  loadSatuan() {
    this.db.list('/debug/satuan/').snapshotChanges().subscribe(item => {
      this.listSatuan = [];
      item.forEach(e => {
        this.listSatuan.push(GetSatuan(e.key, e.payload.val()['viewValue']));
      });
      this.dataSource = new MatTableDataSource(this.listSatuan);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  AddData(): void {
    const dialogRef = this.dialog.open(addDialog, {
      width: '250px',
      hasBackdrop: true,
      panelClass: 'addsatuan-dialog',
      data: {
        action: 'new',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  EditData(e): void {
    const dialogRef = this.dialog.open(addDialog, {
      width: '250px',
      hasBackdrop: true,
      panelClass: 'addsatuan-dialog',
      data: {
        action: 'edit',
        item: e,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
  onDelete(e) {
    // console.log(e);
    var r = confirm("Yakin ingin menghapus " + e.viewValue + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref('debug/satuan/' + e.value).remove().then(() => {
        this.closeSpinner();
      });
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }
}
@Component({
  selector: 'addsatuan-dialog',
  templateUrl: 'dialogsatuan/dialogsatuan.html',
  styleUrls: ['./dialogsatuan/dialogsatuan.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class addDialog {
  SatuanForm: FormGroup;
  ModelSatuan: any = [];
  title: string;

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;
  constructor(
    public dialogRef: MatDialogRef<addDialog>,
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.SatuanForm = new FormGroup({
      viewValue: new FormControl(''),
    });

    if (this.data.action === 'new') {
      this.title = 'Tambah';
    } else {
      console.log(this.data.item);
      this.title = 'Edit';
      this.ModelSatuan.viewValue = this.data.item.viewValue;
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  onSave() {
    var currentUnixTime = Date.now();
    if (this.ModelSatuan.viewValue === '' || this.ModelSatuan.viewValue === undefined) {
      alert('Nama tidak boleh kosong');
      return;
    }

    if (this.data.action === 'new') {
      this.loadSpinner();
      this.db.database.ref('/debug/satuan/' + Math.floor(Date.now() / 1000) + '/').set({
        viewValue: this.ModelSatuan.viewValue,
      }).then(() => {
        this.closeSpinner();
        this.dialogRef.close();
      })
    } else {
      this.loadSpinner();
      this.db.database.ref('/debug/satuan/' + this.data.item.value + '/viewValue/').set(this.ModelSatuan.viewValue).then(() => {
        this.closeSpinner();
        this.dialogRef.close();
      })
    }
  }
}
function GetSatuan(value: string, viewValue: string): Satuan {
  return {
    value: value,
    viewValue: viewValue,
  };
}

export interface Satuan {
  value: string;
  viewValue: string;
}
export const COMPONENT_LIST = [
  MasterSatuanComponent, addDialog,
]