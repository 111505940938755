import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
} from "@angular/material";

import { FuseSharedModule } from "@fuse/shared.module";
import { DaftarMenu } from "./daftarmenu.component";
import { COMPONENT_LIST } from "./daftarmenu.component";
import { AppOverlayModule } from "../../overlay/overlay.module";
import {
    ProgressSpinnerModule,
    ProgressSpinnerComponent,
} from "../../progress-spinner/progress-spinner.module";
import {
    BrowserModule,
    HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxCurrencyModule } from "ngx-currency";
import { CKEditorModule } from "ckeditor4-angular";

import * as jquery from "jquery";
window["jQuery"] = jquery;
window["$"] = jquery;

const routes = [
    {
        path: "listmenu",
        component: DaftarMenu,
    },
];

@NgModule({
    declarations: [[...COMPONENT_LIST], DaftarMenu],
    providers: [],
    entryComponents: COMPONENT_LIST,
    imports: [
        RouterModule.forChild(routes),
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        TranslateModule,
        AppOverlayModule,
        ProgressSpinnerModule,
        BrowserModule,
        FuseSharedModule,
        ImageCropperModule,
        CKEditorModule,
        //curency mask
        NgxCurrencyModule,
    ],
    exports: [
        DaftarMenu,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSlideToggleModule,
        MatSliderModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
    ],
})
export class DaftarMenuModule {}
