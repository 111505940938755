import { Component, OnInit, ViewChild, Inject, ViewEncapsulation } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'app/services/api.service';
import { FormGroup, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'kurir-list',
  templateUrl: './kurir-list.component.html',
  styleUrls: ['./kurir-list.component.scss']
})
export class KurirListComponent implements OnInit {
  displayedColumns = ['nama', 'kontak'];

  dialogRef: any;
  listkurir: ikurir[] = [];
  dataSource: MatTableDataSource<ikurir>;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private db: AngularFireDatabase,
    private af: AngularFireAuth,
    private storage: AngularFireStorage,
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService, public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
  }

  ngOnInit() {
    this.loadkurir();
  }

  loadkurir() {
    this.db.list('/debug/kurirlist/').snapshotChanges().subscribe(kurirs => {
      this.listkurir = [];
      kurirs.forEach(e => {
        this.listkurir.push(getListkurir(e));
      });
      this.dataSource = new MatTableDataSource(this.listkurir);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      console.log(this.dataSource);
    })
  }

  addkurir(): void {
    this.dialogRef = this.dialog.open(dialog_kurir, {
      panelClass: 'addkurir',
      width: '35%',
      data: {
        action: 'new',
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }

  onEdit(e):void{
    this.dialogRef = this.dialog.open(dialog_kurir, {
      panelClass: 'addkurir',
      width: '35%',
      data: {
        action: 'edit',
        item : e,
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {

    });
  }
}
@Component({
  selector: 'addkurir',
  templateUrl: 'addkurir/addkurir.html',
  styleUrls: ['addkurir/addkurir.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class dialog_kurir {
  kurirFrom: FormGroup;
  ModelKurir: any = [];
  title: string;
  tombol: string;
  currentUnixTime = Date.now();

  color = 'accent';
  mode = 'indeterminate';
  value = 80;
  spinnerWithoutBackdrop = false;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFireDatabase,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<dialog_kurir>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.kurirFrom = new FormGroup({
      nama: new FormControl(''),
      kontak: new FormControl(''),
    });

    if (this.data.action === 'new') {
      this.ModelKurir = [];
      this.title = 'Tambah';
      this.tombol = 'Simpan';
    } else {
      console.log(this.data.item);
      this.title = 'Edit';
      this.tombol = 'Edit';
      this.ModelKurir = this.data.item;
    }
  }

  loadSpinner() {
    this.spinnerWithoutBackdrop = true;
  }
  closeSpinner() {
    this.spinnerWithoutBackdrop = false;
  }

  onSave() {
    // this.validasi(this.ModelKurir);
    this.loadSpinner();
    if(this.data.action === 'new'){
      console.log(this.ModelKurir);
      this.db.database.ref('/debug/kurirlist/' + this.currentUnixTime + '/').set({
        nama: this.ModelKurir.nama,
        kontak: this.ModelKurir.kontak.substring(0,1) === '0' ? '62' + this.ModelKurir.kontak.substring(1,13) : this.ModelKurir.kontak,
      }).then(() => {
        this.closeSpinner();
        this.dialogRef.close();
      });
    }else{
      this.db.database.ref('/debug/kurirlist/' + this.data.item.id + '/').set({
        nama: this.ModelKurir.nama,
        kontak: this.ModelKurir.kontak,
      }).then(() => {
        this.closeSpinner();
        this.dialogRef.close();
      });
    }
  }

  onDelete(){
    var r = confirm("Yakin ingin menghapus " + this.data.item.nama + " ?");
    if (r == true) {
      this.loadSpinner();
      this.db.database.ref('debug/kurirlist/' + this.data.item.id).remove();
      this.closeSpinner();
      this.dialogRef.close();
    }
  }
}
function getListkurir(data: any): ikurir {
  return {
    id: data.key,
    nama: data.payload.val()['nama'],
    kontak: data.payload.val()['kontak'],
  }
}
export interface ikurir {
  id: string;
  nama: string;
  kontak: string;
}
export const COMPONENT_LIST = [
  KurirListComponent, dialog_kurir
]