import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatPaginator, MatSort, MatTableDataSource, MatProgressSpinnerModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA, throwMatDuplicatedDrawerError } from '@angular/material';
import { auth } from 'firebase/app';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
@Component({
    selector     : 'login-2',
    templateUrl  : './login-2.component.html',
    styleUrls    : ['./login-2.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class Login2Component implements OnInit
    {   
        color = 'accent';
        mode = 'indeterminate';
        value = 80;
        spinnerWithoutBackdrop = false;

        islogged;
        pesanerror: any = [];
        loginForm: FormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        public afAuth: AngularFireAuth,
        private db: AngularFireDatabase,
        private router: Router,
    )
    {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this._formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }

    loadSpinner() {
        this.spinnerWithoutBackdrop = true;
    }
    closeSpinner() {
        this.spinnerWithoutBackdrop = false;
    }

    isLoggedIn() {
        this.islogged = this.afAuth.auth.currentUser;
    }
    login() {
        var email = this.loginForm.controls.email.value;
        var password = this.loginForm.controls.password.value;
        this.loadSpinner();
        this.afAuth.auth.signInWithEmailAndPassword(email, password).then((res) => {
            localStorage.setItem(
                'AUTH',
                JSON.stringify({ uid: res.user.uid, nama: res.user.displayName, email: res.user.email })
            );
            this.closeSpinner();
            setTimeout(() => {  
                this.router.navigate(['/listmenu'])
            }, 1000);
        }).catch((e) => {
            this.closeSpinner();
            if (e.code === 'auth/wrong-password') {
                this.pesanerror = 'Password Anda salah';
            } else {
                this.pesanerror = 'ID User salah / tidak terdaftar';
            }
        });
    }
}
